import Vue from 'vue';
import Vuex from 'vuex';
import AuthModule from './auth';
import AbillsModule from "@/store/abills";
import { getModule } from 'vuex-module-decorators';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth: AuthModule,
    abills: AbillsModule
  },
});

export default store;
export const authModule = getModule(AuthModule, store);
export const abillsModule = getModule(AbillsModule, store);

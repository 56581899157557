import { AxiosResponse } from 'axios';
import axios from 'axios';
import { API_URL } from '@/settings';
import { Customers } from '@/types/customers';
import { Recipients } from '@/types/broadcast';
import { authHeader } from '@/helpers/auth-header';
import { transformAndValidate } from 'class-transformer-validator';
import {Admin, Group, Groups, MainGroup, MainGroups} from '@/types/abills';
import { formatISO9075 } from 'date-fns';
import { AutoanswerSet, AutoanswersHistory } from '@/types/autoanswer';

export async function getCustomers(): Promise<Customers> {
    const resp = await axios.get(`${API_URL}/customers`, {headers: authHeader()});
    return resp.data;
}

export async function sendBroadcast(message: string, recipientsArray: Recipients[], to_all: boolean) {
    // TODO types!
    await axios.post(`${API_URL}/broadcast`, {message, recipientsArray, to_all}, {headers: authHeader()});
}

// TODO types!
export async function getBroadcastsHistory(): Promise<any> {
    const resp = await axios.get(`${API_URL}/history/broadcasts`, {headers: authHeader()});
    return resp.data;
}

// TODO types!
export async function getStatisticSessions(start_datetime: Date, end_datetime: Date): Promise<any> {
    const resp = await axios.get(`${API_URL}/statistic/sessions`, {
        headers: authHeader(),
        params:
            {
                start_datetime: formatISO9075(start_datetime),
                end_datetime: formatISO9075(end_datetime),
            },
    });
    return resp.data;
}

// TODO types!
export async function getDailyStatisticSessions(): Promise<any> {
    const resp = await axios.get(`${API_URL}/statistic/daily/sessions`, {headers: authHeader()});
    return resp.data;
}

// TODO types!
export async function getMonthlyStatisticSessions(): Promise<any> {
    const resp = await axios.get(`${API_URL}/statistic/monthly/sessions`, {headers: authHeader()});
    return resp.data;
}


// TODO type!
export async function searchAbillsUsers(q: string): Promise<any> {
    const resp = await axios.get(`${API_URL}/abills/users`, {headers: authHeader(), params: {q}});
    return resp.data;
}

// TODO type!
export async function getStreets(): Promise<string[]> {
    const resp = await axios.get(`${API_URL}/abills/streets`, {headers: authHeader()});
    return resp.data.streets;
}

export async function getGroups(): Promise<Group[]> {
    const resp = await axios.get(`${API_URL}/abills/groups`, {headers: authHeader()});
    const data = await transformAndValidate(Groups, resp.data as object);
    return data.groups;
}

export async function getMainGroups(): Promise<MainGroup[]> {
    const resp = await axios.get(`${API_URL}/abills/main_groups`, {headers: authHeader()});
    const data = await transformAndValidate(MainGroups, resp.data as object);
    return data.main_groups;
}

// TODO type!
export async function searchAbillsUsersDownloadExcel(q: string): Promise<Blob> {
    const resp = await axios.get(`${API_URL}/abills/users/download_excel`, {
        headers: authHeader(),
        params: {q},
        responseType: 'arraybuffer',
    });
    return new Blob([resp.data], {type: resp.headers['Content-Type']});
}


// AUTOANSWER
//==========================================================
// TODO type!
export async function getGlobalFailure(): Promise<boolean> {
    const resp = await axios.get(`${API_URL}/autoanswer/get_global_failure`, {
        headers: authHeader(),
    });
    return resp.data.global_failure;
}

// TODO type!
export async function setGlobalFailure(enable: boolean): Promise<any> {
    const resp = await axios.post(`${API_URL}/autoanswer/set_global_failure/${enable}`, null, {
        headers: authHeader(),
    });
    return true;
}

// TODO type!
export async function removeAutoanswer(autoanswer: AutoanswersHistory): Promise<any> {
    const resp = await axios.post(`${API_URL}/autoanswer/remove`,
        { ...autoanswer },
        {
            headers: authHeader(),
        });
    return true;
}

// TODO type!
export async function setAutoanswer(recipients: Recipients[]): Promise<any> {
    const resp = await axios.put(`${API_URL}/autoanswer/set`,
        {
            recipientsArray: recipients, global_failure: false,
        },
        {
            headers: authHeader(),
        });
    return true;
}

// TODO type!
export async function getCurrentProblems(): Promise<any> {
    const resp = await axios.get(`${API_URL}/autoanswer/get_current_problems`, {
        headers: authHeader(),
    });
    return transformAndValidate(AutoanswersHistory, resp.data.current_problems as object[]);
}

// PAYMENTS
// ===================================================================
// TODO type!
export async function getDailyPayments(date: string,
                                       bonuses = true,
                                       cash = true,
                                       bank = true,
                                       external = true,
                                       dsc_filter: string | null,
                                       maingid: number | null, ): Promise<any> {
    const resp = await axios.get(`${API_URL}/abills/daily_payments`, {
        headers: authHeader(),
        params: {
            date,
            bonuses,
            cash,
            bank,
            external,
            dsc_filter,
            maingid,
        },
    });
    return resp.data.payments;
}

// TODO type!
export async function getMonthlyPayments(date: string,
                                         bonuses = true,
                                         cash = true,
                                         bank = true,
                                         external = true,
                                         dsc_filter: string | null,
                                         maingid: number | null): Promise<any> {
    const resp = await axios.get(`${API_URL}/abills/monthly_payments`, {
        headers: authHeader(),
        params: {
            date,
            bonuses,
            cash,
            bank,
            external,
            dsc_filter,
            maingid,
        },
    });
    return resp.data.payments;
}

// TODO type!
export async function getMonthlyPaymentsExcel(date: string,
                                              bonuses = true,
                                              cash = true,
                                              bank = true,
                                              external = true,
                                              dsc_filter: string | null,
                                              maingid: number | null): Promise<Blob> {
    const resp = await axios.get(`${API_URL}/abills/monthly_payments_excel`, {
        headers: authHeader(),
        params: {
            date,
            bonuses,
            cash,
            bank,
            external,
            dsc_filter,
            maingid,
        },
        responseType: 'arraybuffer',
    });
    return new Blob([resp.data], {type: resp.headers['Content-Type']});
}



// TODO types!
export async function getMe(): Promise<Admin> {
    const resp = await axios.get(`${API_URL}/me`, {headers: authHeader()});
    return resp.data.me;
}

// TODO types!
export async function getCallerID(): Promise<any> {
    const resp = await axios.get(`${API_URL}/caller_id`, {headers: authHeader()});
    return resp.data.calls;
}








import { Component, Prop, Vue } from 'vue-property-decorator';
import Main from "@/components/CustomersList.vue";

@Component
export default class App extends Vue {

}

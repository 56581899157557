import 'core-js/stable';
import 'reflect-metadata';
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
//import { Vuetify } from 'vuetify';
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'
import Chartkick from 'vue-chartkick';
import Highcharts from 'highcharts';
const VueFusionCharts = require('vue-fusioncharts');
import FusionCharts from 'fusioncharts';
import TimeSeries from 'fusioncharts/fusioncharts.timeseries';

Vue.use(Chartkick.use(Highcharts));
Vue.use(VueFusionCharts, FusionCharts, TimeSeries);


Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');

import {IsArray, IsNumber, IsNotEmpty, IsOptional, IsString, ValidateNested, IsBoolean} from 'class-validator';
import { Type } from 'class-transformer';

export class Group {
    @IsNumber()
    gid!: number;
    @IsString()
    name!: string;
    @IsNumber()
    maingid!: number;
}

export class Groups {
    @IsArray()
    @Type(() => Group)
    @ValidateNested()
    groups!: Group[];
}

export class MainGroup {
    @IsNumber()
    id!: number;
    @IsString()
    name!: string;
}

export class Admin {
    @IsString()
    id: string = '';
    @IsString()
    name: string = ''
    @IsString()
    grp: string = '';
    @IsBoolean()
    disable: boolean = true;
}

export class MainGroups {
    @IsArray()
    @Type(() => MainGroup)
    @ValidateNested()
    main_groups!: MainGroup[];
}

export type Streets = string[];


import { IsArray, IsNumber, IsNotEmpty, IsOptional, IsString, ValidateNested, IsDate, IsBoolean } from 'class-validator';
import { Type, Transform } from 'class-transformer';
import { parseISO } from 'date-fns';

import { Recipients } from '@/types/broadcast';
import { Group } from '@/types/abills';

export class AutoanswerSet{
    @IsArray()
    @IsOptional()
    recipientsArray!: Recipients[] | null;
    @IsBoolean()
    @IsOptional()
    global_failure!: boolean | null
}

export class AutoanswersHistory {
    @IsString()
    @IsNotEmpty()
    uuid!: string;
    @IsDate()
    @IsNotEmpty()
    @Transform(value => parseISO(value))
    datetime!: Date;
    @IsBoolean()
    @IsNotEmpty()
    enable!: boolean;
    @Type(() => AutoanswerSet)
    @ValidateNested()
    autoanswer_set!: AutoanswerSet;
    @IsString()
    @IsNotEmpty()
    targets!: string;
    @IsString()
    @IsNotEmpty()
    user!: string;
    @IsNumber()
    @IsOptional()
    clients_count!: number | null;
}

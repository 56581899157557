import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import ru from 'vuetify/src/locale/ru';

const vuetify = new Vuetify({
    lang: {
      locales: { ru },
      current: 'ru',
    },
});

Vue.use(Vuetify);

export default vuetify;

import axios from 'axios'
import { API_URL } from '@/settings'
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {User} from "@/types";

@Module({ namespaced: true, name: 'auth' })
export default class AuthModule extends VuexModule {
  user: User | null = null;

  get authenticated(): boolean {
    return this.user != null;
  }

  get group(): string {
    if (this.user) {
      return this.user.group;
    }
    return '';
  }

  @Mutation
  setUser(user: User) {
    this.user = user;
  }

  @Mutation
  clearUser() {
    this.user = null;
  }
}


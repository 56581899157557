import Vue from 'vue';
import VueRouter from 'vue-router';
import { authModule } from '@/store';

Vue.use(VueRouter);

export const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue'),
    },
    {
        path: '/',
        name: 'main',
        component: () => import(/* webpackChunkName: "Main" */ '@/views/Main.vue'),
        children: [
            {
                path: 'customers',
                name: 'customers',
                component: () => import(/* webpackChunkName: "CustomersList" */ '@/components/CustomersList.vue'),
            },
            {
                path: 'history/broadcasts',
                name: 'broadcasts_history',
                component: () => import(/* webpackChunkName: "BroadcastsHistory" */ '@/components/BroadcastsHistoryList.vue'),
            },
            {
                path: 'search',
                name: 'search',
                component: () => import(/* webpackChunkName: "SearchAbillsUsersList" */ '@/components/SearchUsers.vue'),
            },
            {
                path: 'create_broadcast_message',
                name: 'createBroadcastMessage',
                component: () => import(/* webpackChunkName: "CreateBroadcastMessageDialog" */ '@/components/CreateBroadcastMessageDialog.vue'),
            },
            {
                path: 'statistic/sessions',
                name: 'statistic_sessions',
                component: () => import(/* webpackChunkName: "StatisticSessions" */ '@/components/StatisticSessions.vue'),
            },
            {
                path: 'autoanswer/settings',
                name: 'autoanswer_settings',
                component: () => import(/* webpackChunkName: "EditAutoanswerSettings" */ '@/components/EditAutoanswerSettings.vue'),
            },
            {
                path: 'autoanswer/add',
                name: 'autoanswer_add',
                component: () => import(/* webpackChunkName: "AddAutoanswerSettings" */ '@/components/AddAutoanswerForm.vue'),
            },
            {
                path: 'payments',
                name: 'payments',
                component: () => import(/* webpackChunkName: "PaymentsView" */ '@/views/PaymentsView.vue'),
            },
            {
                path: 'caller_id',
                name: 'caller_id',
                component: () => import(/* webpackChunkName: "PaymentsView" */ '@/components/CallerID.vue'),
            },
        ],
    },
    {
        path: '*',
        name: 'notFound',
        component: () => import(/* webpackChunkName: "NotFound" */ '@/views/NotFound.vue'),
    },
];

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    // redirect to login page if not logged in and trying to access a restricted page
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
        return next({
            path: '/login',
            query: {returnUrl: to.path},
        });
    }
    next();
});

export default router;

import axios from 'axios'
import {API_URL} from '@/settings'
import {Action, Module, Mutation, VuexModule} from 'vuex-module-decorators';
import {Admin, Group, Groups, MainGroup} from '@/types/abills';
import { transformAndValidate } from 'class-transformer-validator';
import {getGroups, getMainGroups, getMe, getStreets} from '@/api';
import {abillsModule} from "@/store/index";

@Module({namespaced: true, name: 'abills'})
export default class AbillsModule extends VuexModule {
    loaded = false;
    groups: Group[] = [];
    mainGroups: MainGroup[] = [];
    streets: string[] = [];
    me : Admin = new Admin;

    @Mutation
    setStreets(streets: string[]) {
        this.streets = streets;
    }

    @Mutation
    setMe(me: Admin) {
        this.me = me;
    }

    @Mutation
    setGroups(groups: Group[]) {
        this.groups = groups;
    }

    @Mutation
    setMainGroups(mainGroups: MainGroup[]) {
        this.mainGroups = mainGroups;
    }

    @Mutation
    setLoaded() {
        this.loaded = true;
    }

    @Action({ rawError: true })
    async fetch() {
        if (!this.loaded) {
            const streets = await getStreets();
            const groups = await getGroups();
            const mainGroups = await getMainGroups();
            const me = await getMe();
            this.context.commit('setStreets', streets);
            this.context.commit('setGroups', groups);
            this.context.commit('setMainGroups', mainGroups);
            this.context.commit('setMe', me);
            this.context.commit('setLoaded');
        }
    }
}

